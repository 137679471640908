import React from "react";
import CountUp from "react-countup";
import TrackVisibility from "react-on-screen";
import ScrollAnimation from "react-animate-on-scroll";

const Data = [
  {
    countNum: 199,
    countTitle: "Happy Clients.",
  },
  {
    countNum: 575,
    countTitle: "Employees",
  },
  {
    countNum: 69,
    countTitle: "Useful Programs",
  },
  {
    countNum: 500,
    countTitle: "Useful Programs",
  },
];

const SlipTwo = () => {
  return (
    <div className="rn-splite-style">
      <div className="split-wrapper">
        <div className="row no-gutters radius-10 align-items-center">
          {/* <div className="col-lg-12 col-xl-6 col-12">
                            <div className="thumbnail">
                                <img src="./images/split/split-02.jpg" alt="split Images" />
                            </div>
                        </div> */}
          <div className="col-lg-12 col-xl-12 col-12">
            <div>
              {/* <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <h4 className="title">Continue Your Business With Us.</h4>
                                </ScrollAnimation> */}
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <p className="description">
                  <strong>Experienced and Qualified Instructors:</strong> Our team, comprised of
                  retired military officers and ex-cadets, brings real-world
                  experiences to the classroom, offering students unparalleled
                  insights.
                </p>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <p className="description">
                  <strong>Learning Management System:</strong> We employ a fully automated system
                  that enhances learning by enabling students and instructors to
                  seamlessly track and assess progress.
                </p>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <p className="description">
                  <strong>Founder's Vision:</strong> iCAN Academy is a direct reflection of our
                  Founder's vision, emphasizing genuine student growth and
                  empowerment.
                </p>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <p className="description">
                  <strong>Comprehensive Approach:</strong> Beyond academics, we emphasize
                  physical health, psychological strength, and life skills,
                  providing a holistic education.
                </p>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SlipTwo;
