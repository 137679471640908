import React, { useState, useEffect } from "react";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import SectionTitle from "../sectionTitle/SectionTitle";
import AboutFour from "../aboutc/AboutFour";
import { useLocation } from "react-router-dom";

const About = () => {
  const location = useLocation();
  const { title, content, image } = location.state || {};

  return (
    <>
      <SEO title="About || iCAN - Make It Possible" />
      <Layout>
        <div className="main-content">
          {/* Start Elements Area  */}
          <div className="rwt-About-area rn-section-gap">
            <div className="wrapper plr--65 plr_sm--15">
              <div className="row mb--20">
                <div className="col-lg-12">
                  <SectionTitle
                    textAlign="text-center"
                    radiusRounded=""
                    subtitle="About Us"
                    title="Discover Our Story"
                    description=""
                  />
                </div>
              </div>
              {/* <AboutFour
                image="./images/about/contact-image.png"
                column="col-lg-6 col-xl-3 col-md-6 col-12 mt--30"
                AboutStyle="About-style-three"
              /> */}
              <AboutFour title={title} image={image} content={content} />
            </div>
          </div>
          {/* End Elements Area  */}
        </div>
      </Layout>
    </>
  );
};
export default About;
