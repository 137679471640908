import React, {useEffect, useState} from 'react';
import SEO from "../common/SEO";
import Layout from "../common/Layout";
import Axios from 'axios';
import EventsDetailsContent from '../components/newsEvents/EventsDetailsContent';

const EventsDetails = ({match: {params: {id}}}) => {
    // const blogId = parseInt(id, 10)
    // const data = BlogClassicData.filter(blog => blog.id === blogId);
    const [ news, setNews ] = useState({});

    const getOneNews = async () => {
        const abortController = new AbortController();
        const signal = abortController.signal;

        const getNews = async () => {
            const res = await Axios.get(`${process.env.REACT_APP_API_URL}/news-events/${id}`, {
                signal
            });

            if (res.status === 404) {
                setNews({})
            } else {
                setNews(res.data.data)
            }
        }
        getNews();
        return () => {
            abortController.abort();
        }
    }

    useEffect(() => {
        getOneNews();
    },[]);

    return (
        <>
            <SEO title="Event Details || iCAN" />
            <Layout>
                <div className="rn-blog-details-area">
                    <EventsDetailsContent data={news}  />
                </div>
            </Layout>
        </>
    )
}
export default EventsDetails;