import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PageScrollTop from "./components/pageToTop/PageScrollTop";

// Pages import Here
import Splash from "./pages/Splash";
import HomeDefault from "./pages/HomeDefault";
import BusinessConsulting2 from "./pages/BusinessConsulting2";
import Corporate from "./pages/Corporate";
import Business from "./pages/Business";
import DigitalAgency from "./pages/DigitalAgency";
import Finance from "./pages/Finance";
import Company from "./pages/Company";
import Marketing from "./pages/Marketing";
import TravelAgency from "./pages/TravelAgency";
import Consulting from "./pages/Consulting";
import SeoAgency from "./pages/SeoAgency";
import PersonalPortfolio from "./pages/PersonalPortfolio";
import EventConference from "./pages/EventConference";
import CreativePortfolio from "./pages/CreativePortfolio";
import Freelancer from "./pages/Freelancer";
import InternationalConsulting from "./pages/InternationalConsulting";
import Startup from "./pages/Startup";
import WebAgency from "./pages/WebAgency";

import CategoryList from "./pages/CategoryList";
import TagList from "./pages/TagList";
import Author from "./pages/Author";
import AboutUs from "./pages/AboutUs";

// Elements import Here

import Elements from "./elements/elements/Elements";
import Button from "./elements/button/Button";
import Service from "./elements/service/Service";
import CounterUp from "./elements/counterup/CounterUp";
import Progressbar from "./elements/progressbar/Progressbar";

import Portfolio from "./elements/portfolio/Portfolio";
import portfolioThreeColumn from "./elements/portfolio/PortfolioThreeColumn";
import PortfolioFullWidth from "./elements/portfolio/PortfolioFullWidth";
import PortfolioGrid from "./elements/portfolio/PortfolioGrid";
import PortfolioBoxLayout from "./elements/portfolio/PortfolioBoxLayout";
import PortfolioDetails from "./pages/PortfolioDetails";
import Social from "./elements/social/Social";
import Team from "./elements/team/Team";
import Timeline from "./elements/timeline/Timeline";
import Testimonial from "./elements/testimonial/Testimonial";
import RnAccordion from "./elements/accordion/RnAccordion";
import Tab from "./elements/tab/Tab";
import Pricing from "./elements/pricing/Pricing";
import Split from "./elements/split/Split";
import CalltoAction from "./elements/calltoaction/CalltoAction";
import Video from "./elements/video/Video";
import Gallery from "./elements/gallery/Gallery";
import Contact from "./elements/contact/Contact";
import Brand from "./elements/brand/Brand";
import AdvanceTab from "./elements/advancetab/AdvanceTab";
import AdvancePricing from "./elements/advancePricing/AdvancePricing";

import BlogListView from "./components/blog/BlogListView";
import BlogGridSidebar from "./components/blog/BlogGridSidebar";
import BlogListSidebar from "./components/blog/BlogListSidebar";
import BlogDetails from "./pages/BlogDetails";
import Error from "./pages/Error";

// Import Css Here
import "./assets/scss/style.scss";
import NewsGridView from "./components/newsEvents/NewsGridView";
import NewsDetails from "./pages/NewsDetails";
import EventsGridView from "./components/newsEvents/EventsGridView";
import EventsDetailsContent from "./components/newsEvents/EventsDetailsContent";
import Admission from "./elements/admission/Admission";
import AboutFour from "./elements/about/AboutFour";
import About from "./elements/aboutc/About";
import Cadet from "./elements/cadet/Cadet";
import ISSB from "./elements/issb/ISSB";
import EventsDetails from "./pages/EventsDetails";
import CourseDetails from "./pages/CourseDetails";

const App = () => {
  return (
    <Router>
      <PageScrollTop>
        <Switch>
          {/* This is Home */}
          <Route
            path={`${process.env.PUBLIC_URL + "/"}`}
            exact
            component={TravelAgency}
          />
          {/* News Part */}
          <Route
            path={`${process.env.PUBLIC_URL + "/news"}`}
            exact
            component={NewsGridView}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/news-details/:id"}`}
            exact
            component={NewsDetails}
          />

          {/* Course Part */}
          {/* <Route
            path={`${process.env.PUBLIC_URL + "/issb-course"}`}
            exact
            component={ISSB}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/cadet-course"}`}
            exact
            component={Cadet}
          /> */}
          <Route
            path={`${process.env.PUBLIC_URL + "/course-details/:id"}`}
            exact
            component={CourseDetails}
          />
          {/* Events Part */}
          <Route
            path={`${process.env.PUBLIC_URL + "/events"}`}
            exact
            component={EventsGridView}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/events-details/:id"}`}
            exact
            component={EventsDetails}
          />

          {/* Admission Part */}
          <Route
            path={`${process.env.PUBLIC_URL + "/admission"}`}
            exact
            component={Admission}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/gallery"}`}
            exact
            component={Gallery}
          />
          {/* About Part */}
          <Route
            path={`${process.env.PUBLIC_URL + "/about-us"}`}
            exact
            component={About}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/contact"}`}
            exact
            component={Contact}
          />

          <Route
            path={`${process.env.PUBLIC_URL + "/error"}`}
            exact
            component={Error}
          />
        </Switch>
      </PageScrollTop>
    </Router>
  );
};

export default App;
