// import React, { useEffect, useState } from 'react';
// import { FiHeadphones, FiMail, FiMapPin } from 'react-icons/fi';
// import ContactForm from './ContactForm';
// import GoogleMapStyle from './GoogleMapStyle';
// import ApiService from '../../apiService/apiservice';

// const ContactOne = () => {
//   const [contacts, setContacts] = useState({
//     phoneNumbers: [],
//     email: "",
//     locations: [],
//   });

//   useEffect(() => {
//     ApiService  .get(`/contacts`)
//       .then((res) => {
//         const { phoneNumbers, email, locations } = res.data[0];
//         setContacts({ phoneNumbers, email, locations });
//       })
//       .catch((error) => {
//         console.error("Error fetching course data:", error);
//       });
//   }, []);

//   return (
//     <>
//       <div className="row row--15">
//         <div className="col-lg-12">
//           <div className="rn-contact-address mt_dec--30">
//             <div className="row">
//               <div className="col-lg-4 col-md-6 col-12">
//                 <div className="rn-address">
//                   <div className="icon">
//                     <FiHeadphones />
//                   </div>
//                   <div className="inner">
//                     <h4 className="title">Phone Numbers</h4>
//                     {contacts.phoneNumbers.map((number, index) => (
//                       <p key={index}>
//                         <a href={`tel:${number}`}>{number}</a>
//                       </p>
//                     ))}
//                   </div>
//                 </div>
//               </div>

//               <div className="col-lg-4 col-md-6 col-12">
//                 <div className="rn-address">
//                   <div className="icon">
//                     <FiMail />
//                   </div>
//                   <div className="inner">
//                     <h4 className="title">Email Address</h4>
//                     <p>
//                       <a href={`mailto:${contacts.email}`}>{contacts.email}</a>
//                     </p>
//                   </div>
//                 </div>
//               </div>

//               <div className="col-lg-4 col-md-6 col-12">
//                 <div className="rn-address">
//                   <div className="icon">
//                     <FiMapPin />
//                   </div>
//                   <div className="inner">
//                     <h4 className="title">Our Locations</h4>
//                     {contacts.locations.map((location, index) => (
//                       <p key={index}>
//                         <strong>{`Branch ${index + 1}`}</strong> {location}
//                       </p>
//                     ))}
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className="row mt--40 row--15">
//         <div className="col-lg-7">
//           <ContactForm formStyle="contact-form-1" />
//         </div>
//         <div className="col-lg-5 mt_md--30 mt_sm--30">
//           <GoogleMapStyle />
//         </div>
//       </div>
//     </>
//   );
// };

// export default ContactOne;


import React, { useEffect, useState } from 'react';
import { FiHeadphones, FiMail, FiMapPin } from 'react-icons/fi';
import ContactForm from './ContactForm';
import GoogleMapStyle from './GoogleMapStyle';
import ApiService from '../../apiService/apiservice';

const ContactOne = () => {
  const [contacts, setContacts] = useState({
    phoneNumbers: [],
    email: "",
    locations: [],
    latitude: 0,
    longitude: 0
  });

  useEffect(() => {
    ApiService.get(`/contacts`)
      .then((res) => {
        const { phoneNumbers, email, locations, latitude, longitude } = res.data[0];
        setContacts({ phoneNumbers, email, locations, latitude, longitude  });
      })
      .catch((error) => {
        console.error("Error fetching course data:", error);
      });
  }, []);

  return (
    <>
      <div className="row row--15 contact-info-container">
        <div className="col-lg-4 col-md-6 col-12">
          <div className="rn-address h-100">
            <div className="icon">
              <FiHeadphones />
            </div>
            <div className="inner">
              <h4 className="title">Phone Numbers</h4>
              {contacts.phoneNumbers.map((number, index) => (
                <p key={index}>
                  <a href={`tel:${number}`}>{number}</a>
                </p>
              ))}
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-6 col-12">
          <div className="rn-address h-100">
            <div className="icon">
              <FiMail />
            </div>
            <div className="inner">
              <h4 className="title">Email Address</h4>
              <p>
                <a href={`mailto:${contacts.email}`}>{contacts.email}</a>
              </p>
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-6 col-12">
          <div className="rn-address h-100">
            <div className="icon">
              <FiMapPin />
            </div>
            <div className="inner">
              <h4 className="title">Our Locations</h4>
              {contacts.locations.map((location, index) => (
                <p key={index}>
                  <strong>{`Branch ${index + 1}`}</strong> {location}
                </p>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="row mt--40 row--15">
        <div className="col-lg-7">
          <ContactForm formStyle="contact-form-1" />
        </div>
        <div className="col-lg-5 mt_md--30 mt_sm--30">
          <GoogleMapStyle lat={contacts.latitude} lng={contacts.longitude} zoom={11} />
        </div>
      </div>
    </>
  );
};

export default ContactOne;
