import React from 'react';
import {slugify} from "../../../utils";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";


const EventsList = ({ data, StyleVar }) => {
    // const cate = data.categories.map((value, i) => {
    //     return (
    //         <Link to={process.env.PUBLIC_URL + `/category/${slugify(value)}`} key={i}>{value}{i !== data.categories.length - 1 && ","}</Link>
    //     )
    // });

    
    return (
        <div className={`rn-card ${StyleVar}`}>
            <div className="inner">
                <div className="thumbnail">
                    <Link to={process.env.PUBLIC_URL + `/events-details/${data.id}`} className="image">
                        <img src={`${process.env.REACT_APP_BASE_URL}/${data && data.image.substring(data.image.lastIndexOf('public/uploads/'))}`} alt="News Image" />
                    </Link>
                </div>
                <div className="content">
                    <h4 className="title">
                        <Link to={process.env.PUBLIC_URL + `/events-details/${data.id}`}>
                            {data.title}
                        </Link>
                    </h4>
                </div>
            </div>
        </div>
    )
}
EventsList.propTypes = {
    data: PropTypes.object
};
export default EventsList;
