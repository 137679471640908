import React from "react";
import { slugify } from "../../../utils";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const NewsList = ({ data, StyleVar }) => {
  return (
    <div className={`rn-card ${StyleVar}`}>
      <div className="inner">
        <div className="thumbnail">
          <Link
            to={process.env.PUBLIC_URL + `/news-details/${data.id}`}
            className="image"
          >
            <img
              src={`${process.env.REACT_APP_BASE_URL}/${
                data && data.image.substring(data.image.lastIndexOf("public/uploads/"))
              }`}
              alt="News Image"
            />
          </Link>
        </div>
        <div className="content">
          <h4 className="title">
            <Link to={process.env.PUBLIC_URL + `/news-details/${data.id}`}>
              {data.title}
            </Link>
          </h4>
        </div>
      </div>
    </div>
  );
};
NewsList.propTypes = {
  data: PropTypes.object,
};
export default NewsList;
