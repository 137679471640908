import React from 'react';
import {slugify} from "../../utils";
import {Link} from "react-router-dom";
import { FiUser, FiCalendar } from "react-icons/fi";



const EventsDetailsContent = ({ data }) => {
    const { title, image, created_at, content } = data ?? {};
    let imageUrl;
    if (image) {
        const imageFileName = image.substring(image.lastIndexOf('public/uploads/'));
        imageUrl = `${process.env.REACT_APP_BASE_URL}${imageFileName}`;
    }
    return (
        <>
            <div className="post-page-banner rn-section-gapTop">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                            <div className="content text-center">
                                <div className="page-title">
                                    <h1 className="theme-gradient">{title}</h1>
                                </div>
                                <ul className="rn-meta-list">
                                    {/* <li><FiUser /><Link to={process.env.PUBLIC_URL + `/archive/${slugify(data.author)}`}>{data.author}</Link></li> */}
                                    <li><FiCalendar />{created_at}</li>
                                </ul>
                                <div className="thumbnail alignwide mt--60">
                                    <img className="w-100 radius" src={imageUrl} alt="Images" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="blog-details-content pt--60 rn-section-gapBottom">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                            <div className="content">
                                <p dangerouslySetInnerHTML={{__html: content}}></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

                              



        </>
    )
}
export default EventsDetailsContent;
