import React from 'react';
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import SectionTitle from "../sectionTitle/SectionTitle";
import AdmissionOne from "./AdmissionOne";

const Admission = () => {
    return (
        <>
            <SEO title="Admission || iCAN - Make It Possible" />
            <Layout>
                {/* <BreadcrumbOne 
                    title="The Easiest Way To Create Website <br /> Quick Copy & Make site."
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Admission"
                /> */}
                <div className="main-content">
                    {/* Start Admission Area  */}
                    <div className="rwt-Admission-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 mb--40">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Admission"
                                        title = "Unlock Your Potential: Join Our Enriching Educational Journey Today!"
                                        description = ""
                                    />
                                </div>
                            </div>
                            <AdmissionOne />
                        </div>
                    </div>
                    {/* End Admission Area  */}
                </div>
            </Layout>
        </>
    )
}
export default Admission;