import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

const TabOne = () => {
  return (
    <div>
      <div className="row">
        <div className="col-lg-12">
          <Tabs>
            <div className="row row--30 align-items-center">
              <div className="col-lg-12 mt_md--40 mt_sm--40">
                <div className="rn-default-tab">
                  <div className="tab-button-panel">
                    <TabList className="tab-button">
                      <Tab>
                        <div className="rn-tab-button">
                          <button>Brief history</button>
                        </div>
                      </Tab>
                      <Tab>
                        <div className="rn-tab-button">
                          <button>Mission</button>
                        </div>
                      </Tab>
                      <Tab>
                        <div className="rn-tab-button">
                          <button>Vision</button>
                        </div>
                      </Tab>
                    </TabList>
                  </div>

                  <div className="tab-content-panel">
                    <TabPanel>
                      <div className="rn-tab-content">
                        <div className="inner">
                          <p>
                            At iCAN Academy, we recognized a profound need: to
                            prepare the next generation not just for exams, but
                            for life. We envisioned an institution that went
                            beyond traditional teaching, one that nurtured
                            physical health, psychological resilience, and
                            essential life skills alongside academic excellence.
                            Our academy stands as a testament to our commitment
                            to holistic growth, and our unwavering belief that
                            with the right guidance, every student can say with
                            confidence, 'I can make it possible.'
                          </p>
                        </div>
                      </div>
                    </TabPanel>

                    <TabPanel>
                      <div className="rn-tab-content">
                        <div className="inner">
                          <p>
                          Empower the young generation with unwavering confidence, life skills, and academic proficiency, ensuring that they possess the resilience and capabilities to say, "I can make it possible."
                          </p>
                        </div>
                      </div>
                    </TabPanel>

                    <TabPanel>
                      <div className="rn-tab-content">
                        <div className="inner">
                          <p>
                          To be a beacon of transformative education, nurturing the holistic development of students, emphasizing physical fitness, effective communication, psychological well-being, and academic excellence, while preparing them for both life challenges and distinguished institutions like ISSB and Cadet College.
                          </p>
                        </div>
                      </div>
                    </TabPanel>
                  </div>
                </div>
              </div>
            </div>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default TabOne;
