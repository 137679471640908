import React from 'react';
import AdmissionForm from "./AdmissionForm";

const AdmissionOne = () => {
    return (
        <>
            <div className="row mt--40 row--15">
                <div className="col-lg-12">
                    <AdmissionForm formStyle="contact-form-1" />
                </div>
            </div>
        </>
    )
}
export default AdmissionOne;