import React from "react";
import { FiCheck } from "react-icons/fi";
import { MdCancel } from "react-icons/md";

import { Link } from "react-router-dom/cjs/react-router-dom.min";

const PricingTwo = ({ name, description, program }) => {
  return (
    <div className="row row--15">
      {program &&
        program.length > 0 &&
        program.map((p, index) => (
          <div className="col-lg-6 col-md-6 col-12" key={index}>
            <div
              className={`rn-pricing style-2 ${index === 0 ? "active" : ""}`}
            >
              <div className="pricing-table-inner">
                <div className="pricing-header">
                  <h4 className="title">{p.name}</h4>
                  <div className="pricing">
                    <div className="price-wrapper">
                      <span className="currency">&#2547;</span>
                      <span className="price">{p.price}</span>
                    </div>
                  </div>
                </div>
                <div className="pricing-body">
                  <ul className="list-style--1">
                    {p.features.split(",").map((feature, i) => (
                      <li key={i}>
                        <FiCheck /> {feature}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="pricing-footer">
                  <Link to="/admission" className="btn-default btn-border">
                    Get Admission
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default PricingTwo;
