import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import ApiService from "../../apiService/apiservice";

// const ServiceList = [
//     {
//         image: '/images/service/serviice-01.jpg',
//         title: 'Cadet Coaching fa',
//         description: '',
//         url: '/cadet-course'
//     },
//     {
//         image: '/images/service/serviice-01.jpg',
//         title: 'ISSB Coaching',
//         description: '',
//         url: '/issb-course'
//     },
// ]
const ServiceTwo = ({ textAlign, cardStyle }) => {
  const [serviceList, setServiceListData] = useState([]);

  useEffect(() => {
    ApiService.get("/courses")
      .then((response) => {
        setServiceListData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching about data:", error);
      });
  }, []);

  const getFullImageUrl = (fullPath) => {
    const serverUrl = process.env.REACT_APP_BASE_URL; // Your server URL
    const relativePath = fullPath.split('/uploads')[1]; // Extract the part after /uploads
    return `${process.env.REACT_APP_BASE_URL}${relativePath}`;
};

  return (
    <div className="row row--15 service-wrapper">
      {serviceList &&
        serviceList.length > 0 &&
        serviceList.map((val, i) => (
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
            <ScrollAnimation
              animateIn="fadeInUp"
              animateOut="fadeInOut"
              animateOnce={true}
            >
              <div className={`card-box ${cardStyle} ${textAlign}`}>
                <div className="inner">
                  <div className="image">
                    <Link to="#service">
                      <img src={`${process.env.REACT_APP_BASE_URL}/${val.image}`} alt="card Images" />
                    </Link>
                  </div>
                  <div className="content">
                    <h4 className="title mb--20">
                      <Link
                        to="#service"
                        dangerouslySetInnerHTML={{ __html: val.title }}
                      ></Link>
                    </h4>
                    <p
                      className="description b1 color-gray mb--0"
                      dangerouslySetInnerHTML={{ __html: val.description }}
                    ></p>
                    {/* <Link
                      className="btn-default btn-small btn-border"
                      to={`${val.url}`}
                    >
                      Read More
                    </Link> */}
                    <Link
                      className="btn-default btn-small btn-border"
                      to={process.env.PUBLIC_URL + `/course-details/${val.id}`}
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            </ScrollAnimation>
          </div>
        ))}
    </div>
  );
};
export default ServiceTwo;
