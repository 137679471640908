import React from 'react';
import CountUp from 'react-countup';
import TrackVisibility from "react-on-screen";

// const Data = [
//     {
//         countNum : totalStudents,
//         countTitle: 'Total Students.',
//     },
//     {
//         countNum : successStudents,
//         countTitle: 'Successful Students',
//     },
//     {
//         countNum: totalStudents > 0 ? (successStudents / totalStudents * 100).toFixed(2) : 0,
//         countTitle: 'Success Percentage',
//     },
// ];

const CounterUpFour = ({textALign, counterStyle, column, successStudents, totalStudents}) => {
    return (
        <div className="row">
           <div className={`${column}`}>
                    <div className={`count-box ${counterStyle} ${textALign}`}>
                        <TrackVisibility once>
                            {({ isVisible }) => isVisible && 
                                <div className="count-number">{isVisible ? <CountUp end={totalStudents} /> : 0}</div>}
                        </TrackVisibility>
                        <h5 className="counter-title">Total Students</h5>
                    </div>
                </div>

                <div className={`${column}`}>
                    <div className={`count-box ${counterStyle} ${textALign}`}>
                        <TrackVisibility once>
                            {({ isVisible }) => isVisible && 
                                <div className="count-number">{isVisible ? <CountUp end={successStudents} /> : 0}</div>}
                        </TrackVisibility>
                        <h5 className="counter-title">Successful Students</h5>
                    </div>
                </div>

                <div className={`${column}`}>
                    <div className={`count-box ${counterStyle} ${textALign}`}>
                        <TrackVisibility once>
                            {({ isVisible }) => isVisible && 
                                <div className="count-number percentage">{isVisible ? <CountUp end={totalStudents > 0 ? (successStudents / totalStudents * 100).toFixed(2) : 0} /> : 0}</div>}
                        </TrackVisibility>
                        <h5 className="counter-title">Success Percentage</h5>
                    </div>
                </div>
        </div>
    )
}
export default CounterUpFour;