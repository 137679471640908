import axios from 'axios';

// Create an instance of axios with default settings
const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // Replace with your API base URL
  timeout: 10000, // Request timeout
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add a request interceptor
apiClient.interceptors.request.use(
  (config) => {
    // Add any headers or authentication tokens if needed
    // const token = localStorage.getItem('token');
    // if (token) {
    //   config.headers.Authorization = `Bearer ${token}`;
    // }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
apiClient.interceptors.response.use(
  (response) => {
    return response.data; // Directly return response data
  },
  (error) => {
    // Handle errors globally
    console.error('API Error:', error);
    return Promise.reject(error);
  }
);

// Export API client and common API methods
const ApiService = {
  get: (url, params = {}) => apiClient.get(url, { params }),
//   post: (url, data) => apiClient.post(url, data),
//   put: (url, data) => apiClient.put(url, data),
//   delete: (url, params = {}) => apiClient.delete(url, { params }),
};

export default ApiService;
