import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }) => <div>{text}</div>;
class GoogleMapStyle extends Component {
  static defaultProps = {
    center: {
      lat: 23.745219653197715,
      lng: 90.37225183494127
    },
    zoom: 11
  };
  
  render() {
    const { lat, lng, zoom } = this.props;

    return (
      // Important! Always set the container height explicitly
      <div className="google-map-style-1">
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.REACT_APP_MAP_API_KEY }}
          center={{lat, lng}}
          defaultZoom={this.props.zoom}
        >
          <AnyReactComponent
            lat
            lng
            text="iCanbd"
          />
        </GoogleMapReact>
      </div>
    );
  }
}

export default GoogleMapStyle;