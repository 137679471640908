import React from "react";
import VideoTwo from "../video/VideoTwo";
import { FiCheck } from "react-icons/fi";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const PopupData = [
  {
    id: "01",
    image: "./images/about/contact-image.png",
    popupLink: ["https://www.youtube.com/watch?v=xshEZzpS4faQ"],
  },
];

const AboutFour = ({ title, image, content }) => {
  let imageUrl;
  if (image) {
    const imageFileName = image.substring(image.lastIndexOf("public/uploads/"));
    imageUrl = `${process.env.REACT_APP_BASE_URL}${imageFileName}`;
  }

  return (
    <div className="about-area about-style-4 rn-section-gap">
      <div className="container">
        <div className="row row--40 align-items-center">
          <div className="col-lg-6">
            <img
              loading="lazy"
              className="w-100 radius"
              src={imageUrl}
              alt="Images"
            />
          </div>
          <div className="col-lg-6 mt_md--40 mt_sm--40">
            <div className="content">
              <div className="inner">
                <h3 className="title">
                  <strong>{title}</strong>
                </h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: content.substring(0, 600),
                  }}
                ></p>
                <div className="about-btn mt--30">
                  <Link to="/about-us" className="btn-default">
                    Know More
                  </Link>
                </div>
                {/* <Link 
                  to={{
                    pathname: "/about-us",
                    state: {
                      title,
                      content,
                      image
                    },
                  }} 
                  className="btn-default"
                ></Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutFour;
