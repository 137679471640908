import React, { useState, useEffect } from "react";
import VideoTwo from "../video/VideoTwo";
import { FiCheck } from "react-icons/fi";
import TabOne from "../tab/TabOne";
import SectionTitle from "../sectionTitle/SectionTitle";
import SlipTwo from "../split/SlipTwo";
import ApiService from "../../apiService/apiservice";

const PopupData = [
  {
    id: "01",
    image: "./images/about/contact-image.png",
    popupLink: ["https://www.youtube.com/watch?v=xshEZzpS4faQ"],
  },
];

const AboutFour = () => {
  const [aboutData, setAboutData] = useState({
    title: "",
    content: "",
    section: "",
    image: "",
  });

  useEffect(() => {
    // Replace with your API endpoint
    ApiService.get("/company-infos")
      .then((data) => {
        const { title, content, section, image } = data.data[0];
        setAboutData({ title, content, section, image });
      })
      .catch((error) => {
        console.error("Error fetching about data:", error);
      });
  }, []);

  return (
    <div className="about-area about-style-4 rn-section-gap">
      <div className="container">
        <div className="row row--40 align-items-center">
          <div className="col-lg-6">
            <img
              loading="lazy"
              className="w-100 radius"
              src={`${process.env.REACT_APP_BASE_URL}${aboutData.image}`}
              alt="Images"
            />
          </div>
          <div className="col-lg-6 mt_md--40 mt_sm--40">
            <div className="content">
              <div className="inner">
                <h3 className="title">
                  <strong>{aboutData.title}</strong>
                </h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: aboutData.content,
                  }}
                ></p>
              </div>
            </div>
          </div>
        </div>

        <div className="rwt-tab-area rn-section-gap">
          <div className="container">
            <div className="row mb--40">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="About iCAN Academy"
                  title=""
                  description=""
                />
              </div>
            </div>
            <TabOne />
          </div>
        </div>

        <div className="row row--40 align-items-center">
          <div className="container">
            <div className="row mb--40">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Why Us"
                  title="Why We Are Different"
                  description=""
                />
              </div>
            </div>
            <SlipTwo />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutFour;
