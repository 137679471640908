import React from "react";
import { Accordion, Card, Button } from "react-bootstrap";

const AccordionOne = ({ customStyle }) => {
  return (
    <Accordion
      className={`rn-accordion-style ${customStyle}`}
      defaultActiveKey="0"
    >
      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="0">
            What are the eligibility criteria for Cadet?
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            <strong>Nationality: </strong> Candidates must be citizens of
            Bangladesh.
            <br />
            <strong>Age Limit: </strong> Student should be 14 years of age.
            <br />
            <strong>Marital Status: </strong> Usually, unmarried candidates are
            eligible to apply.
            <br />
            <strong>Educational Qualifications: </strong> Students who have
            passed 6th standard can get admission.
            <br />
            <strong>Physical Fitness: </strong> Candidates need to meet certain
            physical fitness standards, including height, weight, and physical
            endurance. There are often specific requirements for male and female
            candidates.
            <br />
            <strong>Vision Standards: </strong> Candidates should meet specific
            vision standards without the need for corrective lenses. Vision
            requirements can vary based on the branch and commission type.
            <br />
            <strong>Character and Conduct: </strong> Candidates are expected to
            have good moral character and conduct. Any involvement in criminal
            activities can disqualify a candidate.
            <br />
            <strong>Medical Fitness: </strong> : Candidates must be in good
            medical health and free from any contagious diseases. They need to
            pass a comprehensive medical examination conducted by military
            medical authorities.
            <br />
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="1">
            What is the height and weight requirement for Cadet?
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="1">
          <Card.Body>
            <strong>Male: </strong> The height criteria for male candidates can
            range from around 5 feet 4 inches (162.5 cm) to 6 feet 2 inches
            (187.5 cm) or similar ranges. The weight requirement is usually
            proportionate to height.
            <br />
            <strong>Female: </strong> The height criteria for male candidates
            can range from around 5 feet 2 inches (157.5 cm) to 5 feet 6 inches
            (167.5 cm) or similar ranges. The weight requirement is usually
            proportionate to height.
            <br />
            <br />
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="2">
            What Kind of Physical Fitness is Required?
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="2">
          <Card.Body>
            <strong>Endurance and Stamina: </strong>
            <br />
            <br />
            Candidates should have good cardiovascular endurance and stamina to
            perform various physical activities over extended periods. This
            includes activities like running, swimming, and marching.
            <br />
            <br />
            <strong>Running: </strong>
            <br />
            <br />
            Running is an integral part of the physical fitness assessment.
            Candidates will be required to complete a certain distance within a
            specified time frame. Practice running regularly to build up your
            endurance.
            <br />
            <br />
            <strong>Strength and Conditioning: </strong>
            <br />
            <br />
            Strength training and conditioning exercises are important to build
            muscular strength and overall physical resilience.
            <br />
            <br />
            <strong>Push-Ups and Sit-Ups: </strong>
            <br />
            <br />
            These exercises are often included in the physical fitness
            assessment to test upper body and core strength
            <br />
            <br />
            <strong>Swimming: </strong>
            <br />
            <br />
            Candidates should have basic swimming skills.
            <br />
            <br />
            <strong>Obstacle Course:</strong>
            <br />
            <br />
            Candidates will have an obstacle course to evaluate agility,
            coordination, and ability to overcome physical challenges.
            <br />
            <br />
            <strong>Flexibility: </strong>
            <br />
            <br />
            Good flexibility is important for overall fitness. Stretching
            exercises can help improve flexibility.
            <br />
            <br />
            <strong>Body Composition:</strong>
            <br />
            <br />
            Candidates should maintain a healthy body composition. Being
            underweight or overweight can affect your ability to meet the
            physical fitness standards.
            <br />
            <br />
            <strong>Sports and Recreational Activities: </strong>
            <br />
            <br />
            Engaging in sports and recreational activities can help improve
            overall fitness and provide a well-rounded physical training
            routine.
            <br />
            <br />
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="3">
            What are the medical standards to apply?
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="3">
          <Card.Body>
            <strong>General Health: </strong>
            <br />
            <br />
            Candidates should be in good overall health without any major
            medical conditions that could hinder their ability to perform
            military duties.
            <br />
            <br />
            <strong>Vision Requirements: </strong>
            <br />
            <br />
            Visual acuity standards are set for different roles. Candidates
            should have good vision without uncorrected visual impairments that
            might affect their performance.
            <br />
            <br />
            <strong>Hearing Standards: </strong>
            <br />
            <br />
            Hearing tests are conducted to ensure candidates have good hearing
            without significant hearing loss or auditory impairments.
            <br />
            <br />
            <strong>Blood Pressure: </strong>
            <br />
            <br />
            Blood pressure levels should be within acceptable ranges to ensure
            cardiovascular health.
            <br />
            <br />
            <strong>Orthopedic Fitness: </strong>
            <br />
            <br />
            Candidates should not have major orthopedic issues or physical
            disabilities that might impact their ability to perform physically
            demanding tasks.
            <br />
            <br />
            <strong>Respiratory Health:</strong>
            <br />
            <br />
            Respiratory function is assessed to ensure candidates can handle
            physical exertion and challenging environments.
            <br />
            <br />
            <strong>Mental Health: </strong>
            <br />
            <br />
            Candidates should have good mental health and stability. Severe
            mental health conditions might lead to disqualification
            <br />
            <br />
            <strong>Medical History:</strong>
            <br />
            <br />
            Candidates are required to provide accurate medical history.
            Concealing relevant medical information can lead to
            disqualification.
            <br />
            <br />
            <strong>Dental Health: </strong>
            <br />
            <br />
            Dental health is assessed to ensure candidates have an adequate
            level of oral health.
            <br />
            <br />
            <strong>Infectious Diseases:</strong>
            <br />
            <br />
            Candidates should be free from contagious diseases that could spread
            within a military setting.
            <br />
            <br />
            <strong>Skin Conditions: </strong>
            <br />
            <br />
            Certain severe or contagious skin conditions might impact
            eligibility.
            <br />
            <br />
            <strong>Physical Fitness: </strong>
            <br />
            <br />
            While not a medical condition, physical fitness is assessed to
            ensure candidates meet the required fitness standards for military
            service.
            <br />
            <br />
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};
export default AccordionOne;
